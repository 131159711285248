/**
 * Created by RKL on 28/08/2015.
 */
define('jqgridView',[
    'module',
    'jquery',
    'backbone.marionette',
    'underscore',
    'app',
    'settings',
    'jqgridFormatters',
    'dateUtils',
    'imageUtils',
    'bootbox',
    'customBootboxMessage',
    'itemPagesNameMixin',
    'jqgrid'
], function (
    module,
    $,
    Marionette,
    _,
    App,
    Settings,
    jqGridFormatters,
    dateConverter,
    imageUtils,
    Bootbox,
    CustomBootboxMessage,
    ItemPagesNameMixin
) {
    'use strict';

    module.exports = Marionette.View.extend({
        filterEnable: true,
        defaultFilters: {},
        GRID_MIN_WIDTH: 1000,

        gridUi: {
                jqGridInput: '.ui-search-input input',
                jqGridSelect: '.ui-search-input select',
                jqGridClear: '.ui-search-clear',
                gridCellWithoutImage: 'td[role=gridcell]:not(.image-cell, .without-tooltip)'
        },

        gridEvents: {
            'change @ui.jqGridSelect': 'onGridSelectClick',
            'change @ui.jqGridInput': 'onGridFilterChange',
            'click @ui.jqGridClear': 'onGridFilterChange'
        },

        initialize: function (options) {
            this.selectDefault = _.i18n('common.all');

            this.base = '';
            if (options && options.isSuperAdmin) {
                this.isSuperAdmin = options.isSuperAdmin;
            }

            this.exportErrors = [];
            if (this.onInitialize) {
                this.onInitialize();
            }
        },

        preselectRow: function (e, rowid) {
            this.triggerMethod('preselectRow', rowid);
        },

        paginationObject: function () {
            return {
                first: 0,
                rowCount: 0,
                rows: this.options.data && this.options.data.rows ? this.options.data.rows : Settings.get('perPage'),
                currentPage: 1
            };
        },

        onRender: function () {
            this.pagination = _.extend({}, this.paginationObject());

            if (this.getSelectValues) {
                this.getSelectValues();
            }

            if (Settings.jqGridLoaded) {
                this.onGridLoaded();
            } else {
                App.on('jqGrid:loaded', _.bind(this.onGridLoaded, this));
            }
            this.ui.jqGrid.bind('jqGridSelectRow', _.bind(this.preselectRow, this));
        },

        getDataFromId: function (id, itemPagesNameMixin) {
            if (itemPagesNameMixin) {
                switch (itemPagesNameMixin) {
                    case ItemPagesNameMixin.SUPERADMIN_DYNAMICDEFINITIONS:
                        return _.findWhere(this.data, {name: id});
                }
            }
            if (this.data) {
                if (parseInt(id, 10).toString() === id) {
                    var index = parseInt(id, 10);
                    return this.data[index - 1];
                } else {
                    return _.findWhere(this.data, {secId: id});
                }
            }
            return null;
        },

        findFilterName: function () {
            if (typeof this.filtersName === 'function') {
                return this.filtersName();
            }
            return this.filtersName;
        },

        gridInitOptions: function (data) {
            this.sidx = this.sidx || '';
            this.sord = this.sord || 'asc';
            this.displayRowToolTip = data.displayRowToolTip || false;
            var postData = Settings.getFromMemory(this.findFilterName());
            if (postData) {
                this.sidx = postData.sidx;
                this.sord = postData.sord;
                this.pagination.currentPage = postData.page || 1;
            }

            var rowList = [10, 20, 30, 40, 50];
            if (this.pagination.rows) {
                rowList.push(this.pagination.rows);
                // order the rowList
                rowList = _.sortBy(rowList, function (num) {
                    return num;
                });
            }
            return {
                regional: Settings.get('lang'),
                url: data.url,
                mtype: 'GET',
                datatype: data.url ? 'json' : 'local',
                colModel: [],
                viewrecords: true,
                height: '100%',
                rowNum: this.pagination.rows,
                rowList: rowList,
                page: this.pagination.currentPage,
                multiselect: false,
                multiboxonly: true,
                multiselectWidth: 15,
                jsonReader: {
                    page: function (obj) {
                        if (obj.length === 0) {
                            data.rowCount = 1;
                            return 1;
                        }
                    },
                    total: _.bind(function () {
                        return Math.ceil(data.rowCount / this.pagination.rows);
                    }, this),
                    records: function () {
                        return data.rowCount;
                    }
                },
                sortname: this.sidx,
                sortorder: this.sord,
                postData: postData || {
                    rows: this.pagination.rows,
                    first: this.pagination.first
                },
                pager: this.ui.jqGridPager,
                // viewsortcols: [true, 'vertical', true],
                data: data.data,
                isSuperAdmin: this.isSuperAdmin,

                onPaging: _.bind(function (pgButton) {
                    var jqGridPostData = this.ui.jqGrid.getPostData(),
                        currentPage = this.pagination.currentPage,
                        rows = this.pagination.rows,
                        rowCount = this.pagination.rowCount;

                    switch (pgButton) {
                        case 'first':
                            currentPage = 1;
                            break;
                        case 'next':
                            currentPage++;
                            break;
                        case 'prev':
                            currentPage--;
                            break;
                        case 'last':
                            currentPage = Math.ceil(rowCount / rows);
                            break;
                    }

                    this.pagination.currentPage = currentPage;
                    jqGridPostData.first = ((currentPage - 1) * rows);
                    this.ui.jqGrid.setPostData(jqGridPostData);
                }, this),

                serializeGridData: _.bind(function (postData) {
                    var currentPage = Math.ceil(this.pagination.rowCount / this.pagination.rows);
                    if (this.filterChange === true) {
                        postData.first = 0;
                        this.pagination.currentPage = 1;
                        postData.page = 1;
                        this.filterChange = false;
                    } else if (this.pagination.currentPage > currentPage) {
                        this.pagination.currentPage = currentPage ? currentPage : 1;
                        postData.page = this.pagination.currentPage;
                        postData.first = ((this.pagination.currentPage - 1) * this.pagination.rows);
                    }

                    if (this.sidx !== postData.sidx || this.sord !== postData.sord) {
                        postData.first = 0;
                        this.pagination.currentPage = 1;
                        postData.page = 1;
                    }

                    this.sidx = postData.sidx;
                    this.sord = postData.sord;

                    var f = _.clone(postData);
                    if (this.keepCurrentPage === false) {
                        f.first = 0;
                        f.page = 1;
                    }

                    Settings.setToMemory(this.findFilterName(), f);

                    return postData;
                }, this),

                gridComplete: _.bind(function () {
                    this.onGridComplete();
                    this.attachPopover();
                    if (this.displayRowToolTip === true) {
                        this.attachTooltip();
                    }
                }, this),

                loadComplete: _.bind(function (data) {
                    if (data.rows) {
                        this.triggerMethod('loadComplete', data.rows);
                        _.each(data.rows, _.bind(function (row) {
                            this.forClipboard(row);
                        }, this));
                        this.data = data.rows;
                    } else {
                        this.triggerMethod('loadComplete', data);
                        _.each(data, _.bind(function (row) {
                            this.forClipboard(row);
                        }, this));
                        this.data = data;
                    }
                }, this)
            };
        },

        forClipboard: function (row) {
            var elements = $('.copyToClipboard[data-row-id="' + row.secId + '"]');
            if (elements.length === 0) {
                return;
            }
            App.trigger('forClipboard', elements[0], elements[0].attributes['data-copy-value'].value);
        },

        onGridLoaded: function () {
            this.trigger('onGridLoaded', this);
        },

        findFilter: function () {
            var storedFilters = Settings.getFromMemory(this.findFilterName());
            if (!storedFilters) {
                storedFilters = {};
                Settings.setToMemory(this.findFilterName(), storedFilters);
            }
            return storedFilters;
        },

        displayGrid: function (data) {
            if (data.url) {
                var urlParams = {
                    rowCountUrl: data.rowCountUrl,
                    filters: this.findFilter()
                };
                this.rowCountUrl = data.rowCountUrl;
                this.data = data;

                this.$el.find('.jq-grid-table-page').append('<div class="alert-info-grid">' + _.i18n('jqgrid.emptyrecords') + '</div>');

                var CommonService = require('services/common');
                CommonService.getRowCount(urlParams)
                    .done(_.bind(function (rowCount) {
                        //display grid only if you have make a search or if you have records
                        this.gridOptionsObj = this.gridOptions(data);
                        this.onRowCountDone(data, rowCount);
                        this.$el.find('.alert-info-grid').hide();
                        this.triggerMethod('checkRowCount', rowCount);
                    }, this)).fail(function (response) {
                    if (response.responseText === '') {
                        Bootbox.alert({title: response.statusText, message: response.statusText});
                    } else {
                        Bootbox.alert({title: response.statusText, message: response.responseText});
                    }
                });
            } else {
                this.data = data;
                this.gridOptionsObj = this.gridOptions(data);
                this.onRowCountDone(data, 1);
            }
        },

        onRowCountDone: function (data, rowCount) {
            data.rowCount = rowCount;
            this.pagination.rowCount = rowCount;
            this.colModel = this.gridOptionsObj.colModel;
            if (this.ui.jqGrid) {
                this.ui.jqGrid.jqGrid(this.gridOptionsObj);
            }

            // activate the toolbar searching
            var activeToolbar = _.any(this.colModel, function (colModel) {
                return colModel.search;
            });
            if (this.ui.jqGrid && activeToolbar) {
                this.ui.jqGrid.jqGrid('filterToolbar', {autosearch: false});
            }

            this.$('.ui-jqgrid-sortable').click(_.bind(function () {
                this.sorting = true;
            }, this));
            _.each(Settings.getFromMemory(this.findFilterName()), _.bind(function (value, key) {
                this.$('input[name=' + key.replaceAll('.', '\\.') + ']').val(value);
            }, this));
        },

        updatePagination: function () {
            var urlParams = {
                rowCountUrl: this.rowCountUrl,
                filters: this.findFilter()
            };
            if (this.ui.jqGrid[0].grid) {
                if (this.rowCountUrl) {
                    var CommonService = require('services/common');
                    CommonService.getRowCount(urlParams)
                        .done(_.bind(function (rowCount) {
                            this.pagination.rowCount = rowCount;
                            this.ui.jqGrid.setGridParam({
                                jsonReader: {
                                    page: _.bind(function () {
                                        return this.pagination.currentPage;
                                    }, this),
                                    total: _.bind(function () {
                                        return Math.ceil(rowCount / this.pagination.rows);
                                    }, this),
                                    records: function () {
                                        return rowCount;
                                    }
                                }
                            });
                            if (this.filterChange === true) {
                                this.ui.jqGrid[0].triggerToolbar();
                            } else {
                                this.ui.jqGrid.trigger('reloadGrid', [{current: this.pagination.currentPage}]);
                            }
                        }, this))
                        .fail(function (response) {
                            Bootbox.alert({title: response.statusText, message: response.responseText});
                        });
                } else {
                    this.ui.jqGrid[0].triggerToolbar();
                }
            } else {
                this.displayGrid(this.data);
            }
        },

        onGridSelectClick: function (e) {
            var target = $(e.currentTarget),
                name = target.attr('name');

            if (!this.selectValue) {
                this.selectValue = {};
                this.selectValue[name] = this.selectDefault;
            }

            if (target.val() !== this.selectValue[name]) {
                this.selectValue[name] = target.val();
                this.onGridFilterChange(e);
            }
        },

        onGridFilterChange: function (e) {
            var filters = this.findFilter(),
                value;

            this.triggerClear(e);

            _.each(this.colModel, _.bind(function (column) {
                if (column.search) {
                    if (column.stype === 'select') {
                        value = this.$('select[name="' + column.index + '"]').val();
                        filters[column.index] = value === this.selectDefault ? '' : value;
                    } else {
                        value = this.$('input[name="' + column.index + '"]').val();
                        filters[column.index] = value;
                    }
                }
            }, this));

            this.setFilter(filters);
            this.filterChange = true;
            this.updatePagination();
        },

        setFilter: function (filters) {
            Settings.setToMemory(this.findFilterName(), filters);
        },

        getRepeatLabelIcon: function () {
            return '<div style="position: relative;">' +
                '<span class="mdi mdi-play mdi-flip-h" style="font-size: 21px; top: 0;"></span>' +
                '<span class="mdi mdi-play mdi-flip-h" style="position: absolute; font-size: 21px; top: 0; left: 6px;"></span>' +
                '</div>';
        },

        getWarningLabelIcon: function () {
            return '<span class="mdi mdi-alert" style="font-size: 21px; top: -1px; color: #ff5200;"></span>';
        },

        getSmpTypeLabelIcon: function () {
            return '<span class="mdi mdi-checkbox-blank-circle-outline" style="font-size: 21px; top: -1px;"></span>';
        },

        getSameAssayLabelIcon: function () {
            return '<div class="existOther" style="overflow: initial; top: 5px;  left: 3px">' +
                '<span class="triangle-other-assay-plateView"></span>' +
                '</div>';
        },

        getOtherAssayLabelIcon: function () {
            return '<div class="existOther" style="overflow: initial; top: 5px;  left: 3px">' +
                '<span class="triangle-same-assay-plateView"></span>' +
                '</div>';
        },

        defaultFormatter: function (cellValue) {
            return cellValue ? cellValue : '';
        },

        typeParamFormatter: function (cellValue, options) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }

            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + (cellValue.type ? cellValue.type + ';' + (cellValue.param ? cellValue.param : '') : '') + '</span>';
        },

        userFormatter: function (cellValue, options, model) {
            var title = '';
            cellValue = model[options.colModel.name] ? model[options.colModel.name].nickName : '';
            if (model[options.colModel.name]) {
                if (model && model[options.colModel.name] && model[options.colModel.name].email) {
                    title = model[options.colModel.name].email;
                }
                if (cellValue === '' && title) {
                    cellValue = title;
                }
                if (model && model[options.colModel.name] && model[options.colModel.name].firstName && model[options.colModel.name].lastName) {
                    title = model[options.colModel.name].firstName + ' ' + model[options.colModel.name].lastName;
                }
            }

            return '<span class="cell-default"' +
                ' data-row-id="' + options.rowId + '"' +
                ' title="' + title + '"' +
                '>' + cellValue + '</span>';
        },

        validationFormatter: function (cellValue) {
            if (parseInt(cellValue, 10) === 3) {
                return '<div title="Validation 1">' +
                    '<span class="mdi mdi-check check-val1-graphAction-icon val-green" style="left: 1px;"></span>' +
                    '</div>';
            }
            if (parseInt(cellValue, 10) === 4) {
                return '<div title="Validation 1&amp;2" style="position: relative;">' +
                    '<span class="mdi mdi-check check-val1-graphAction-icon val-green"></span>' +
                    '<span class="mdi mdi-check check-val2-graphAction-icon val-green"></span>' +
                    '</div>';
            }
            return '';
        },
        iconWellFormatter: function (cellValue, call, object) {
            if (object.valWst === 3.1) {
                return '<span class="mdi mdi-school expertColorIcon well-header-expert-status-icon"></span>';
            }
            var errorWarning;
            _.each(object, function (value, key) {
                if (key.startsWith('target')) {
                    if (value.codeErr) {
                        errorWarning = true;
                    }
                }
            });
            if (errorWarning) {
                return '<span class="mdi mdi-alert warningColorIcon well-header-warning-status-icon"></div>';
            }
            if (object.repeatStatus) {
                return '<div style="position: relative;width: 100%;height: 100%;"><div class="repeatStatus repeat-' + object.repeatStatus + '"><span>' + object.dilution + '</span></div></div>';
            }
            return '';
        },
        copyToClipboardFormatter: function (cellValue, options) {
            return '<span class="mdi-content-content-copy copyToClipboard" ' +
                'title="' + _.i18n('common.copyToClipboard') + '" data-row-id="' + options.rowId +
                '" data-copy-value="' + cellValue + '"></span>';
        },

        concatFormatter: function (cellValue, options, rawData) {
            cellValue = [];
            _.each(options.colModel.name, function (name) {
                if (rawData[name]) {
                    cellValue.push(rawData[name]);
                }
            });

            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue.join(' - ') + '</span>';
        },
        typeFormatter: function (cellValue, options) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }
            cellValue = cellValue.split(';');

            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue[0] + '</span>';
        },
        paramFormatter: function (cellValue, options) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }
            cellValue = cellValue.split(';');
            if (cellValue.length > 0) {
                cellValue = cellValue.slice(1).join(';');
            } else {
                cellValue = '';
            }

            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },
        rawFormatter: function (cellValue, options) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }

            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + String(cellValue).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;') + '</span>';
        },

        defaultListFormatter: function (cellValue, options) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = [];
            }

            cellValue = cellValue.join(', ');
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },

        assayFormatter: function (cellValue, options) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }

            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + _.pluck(cellValue, 'code').join(', ') + '</span>';
        },

        codeNameListFormatter: function (cellValue, options) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }

            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + _.pluck(cellValue, 'code').join(', ') + '</span>';
        },
        booleanFormatterNoCheckBox: function (cellvalue, options) {
            if (cellvalue) {
                return '<span class="mdi mdi-check-bold js-icon" data-row-id="' + options.rowId + '"></span>';
            } else {
                return '';
            }
        },

        lisAnaFormatter: function (cellValue, options) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }

            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + _.pluck(cellValue, 'code').join(', ') + '</span>';
        },

        booleanFormatter: function (cellvalue) {
            var checked = cellvalue ? 'checked' : '',
                htmlEl = '<div class="checkbox-group">' +
                    '<div class="checkbox checkbox-primary list-checkbox"><label>' +
                    '<input disabled="disabled" type="checkbox"' + checked + ' ' +
                    '></label></div></div>';
            return htmlEl;
        },

        simpleBooleanFormatter: function (cellvalue) {
            var checked = cellvalue ? 'checked' : '',
                htmlEl = '<input disabled="disabled" type="checkbox"' + ' ' + checked + ' ' + '>';
            return htmlEl;
        },

        deleteIconFormatter: function (cellValue, options) {
            return '<span class="mdi mdi-delete cell-delete js-icon clickable" ' +
                'data-row-id="' + options.rowId + '"></span>';
        },

        attachmentActionIconFormatter: function (cellValue, options) {
            return '<span class="mdi mdi-attachment mdi-flip-v mdi-rotate-90 cell-attachment js-icon" ' +
                'title="' + _.i18n('attachment') + '" data-row-id="' + options.rowId + '"></span>';
        },

        duplicateActionIconFormatter: function (cellValue, options) {
            return '<span class="mdi mdi-content-duplicate cell-duplicate js-icon" ' +
                'title="' + _.i18n('duplicate') + '" data-row-id="' + options.rowId + '"></span>';
        },

        errorIconFormatter: function (cellValue, options) {
            if (_.isEmpty(cellValue)) {
                return '';
            }
            return '<span class="mdi mdi-alert-octagram cell-alert js-icon" ' +
                'title="' + _.i18n('common.showErrors') + '" data-row-id="' + options.rowId + '"></span>';
        },

        deleteActionIconFormatter: function (cellValue, options, model) {
            var codeName = model.code ? model.code : (model.name ? model.name : (model.sampleId ? model.sampleId : (model.code ? model.code : '')));
            return '<span class="mdi mdi-delete-outline cell-delete js-icon" ' +
                'title="' + _.i18n('delete') +
                '" data-row-codeName="' + codeName +
                '" data-row-id="' + options.rowId + '"></span>';
        },

        archiveRestoreActionIconFormatter: function (cellValue, options, model) {
            var codeName = model.code ? model.code : (model.name ? model.name : (model.sampleId ? model.sampleId : (model.lotId ? model.lotId : '')));
            if (model.archived) {
                return '<span class="mdi mdi-delete-restore cell-unArchive js-icon" ' +
                    'title="' + _.i18n('restore') + '" data-row-id="' + options.rowId + '" data-row-codeName="' + codeName + '"></span>';
            } else {
                return '<span class="mdi mdi-archive-arrow-down-outline cell-archive js-icon" ' +
                    'title="' + _.i18n('archive') + '" data-row-id="' + options.rowId + '" data-row-codeName="' + codeName + '"></span>';
            }
        },

        copyActionIconFormatter: function (cellValue, options) {
            return '<span class="mdi-content-content-copy cell-copy js-icon" ' +
                'title="' + _.i18n('duplicate') + '" data-row-id="' + options.rowId + '"></span>';
        },

        exportActionIconFormatter: function (cellValue, options) {
            return '<span class="mdi mdi-export-variant cell-export js-icon" ' +
                'title="' + _.i18n('export') + '" data-row-id="' + options.rowId + '"></span>';
        },

        copyQCActionIconFormatter: function (cellValue, options, model) {
            if (model.refKit && model.refKit.refCurrentLot && model.secId !== model.refKit.refCurrentLot.secId) {
                return '<span class="mdi mdi-receipt cell-copy-qc js-icon" ' +
                    'data-row-id="' + options.rowId + '"></span>';
            } else {
                return '';
            }
        },

        rowSelectorFormatter: function (cellValue, options) {
            var mdi = 'mdi-checkbox-blank-outline';
            if (options.colModel.checked) {
                mdi = 'mdi-checkbox-marked-outline';
            }
            return '<span class="mdi ' + mdi + ' cell-select" ' +
                'data-row-id="' + options.rowId + '"></span>';
        },

        dateFormatterFromSettings: function (cellValue) {
            var date = '';
            if (cellValue) {
                date = dateConverter.toDateFormatStringFromSettings(cellValue, this.settings);
            }
            return '<span class="cell-date">' + date + '</span>';
        },

        toDateTimeFormatString: function (cellValue) {
            var date = '';
            if (cellValue) {
                date = dateConverter.toDateTimeFormatString(cellValue);
            }
            return '<span class="cell-date">' + date + '</span>';
        },


        dateTimeFormatterFromSettings: function (cellValue) {
            var date = '';
            if (cellValue) {
                date = dateConverter.toDateTimeFormatStringFromSettings(cellValue, this.settings);
            }
            return '<span class="cell-date">' + date + '</span>';
        },

        dateTimeFormatter: function (cellValue) {
            var date = '';
            if (cellValue) {
                date = dateConverter.toDateTimeShortYearFormatString(cellValue);
            }

            return '<span class="cell-date">' + date + '</span>';
        },

        dateTimeSecondeFormatter: function (cellValue) {
            var date = '';
            if (cellValue) {
                date = dateConverter.toDateTimeSecondeFormatString(cellValue);
            }

            return '<span class="cell-date">' + date + '</span>';
        },

        assayWaitingFormatter: function (cellValue, options) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = [];
            }
            var val = [];
            _.each(cellValue, function (value) {
                val.push(value.assay.code + ' (' + value.count + ')');
            });

            var ret = '<span class="cell-default assayWaitingCell" data-row-id="' +
                options.rowId + '">' + val.join(', ') + '</span>';
            return !_.isEmpty(val) ? '<div class="css-prepRun-listView-assayWaiting">' + ret + '</div>' : ret;
        },

        assayRunningFormatter: function (cellValue, options) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = [];
            }
            var val = [];
            _.each(cellValue, function (value) {
                val.push(value.assay.code + ' (' + value.count + ')');
            });

            var ret = '<span class="cell-default assayRunningCell" data-row-id="' +
                options.rowId + '">' + val.join(', ') + '</span>';
            return !_.isEmpty(val) ? '<div class="css-prepRun-listView-assayRunning">' + ret + '</div>' : ret;
        },

        assayDoneFormatter: function (cellValue, options) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = [];
            }
            var val = [];
            _.each(cellValue, function (value) {
                val.push(value.assay.code + ' (' + value.count + ')');
            });

            var ret = '<span class="cell-default assayDoneCell" data-row-id="' +
                options.rowId + '">' + val.join(', ') + '</span>';
            return !_.isEmpty(val) ? '<div class="css-prepRun-listView-assayDone">' + ret + '</div>' : ret;
        },


        wellAssayWaitingFormatter: function (cellValue, options) {
            if (cellValue !== '-') {
                return '<div class="cell-default assayWaitingCell" data-row-id="' +
                    options.rowId + '">' + cellValue + '</div>';
            } else {
                return '<div class="cell-default" data-row-id="' +
                    options.rowId + '"></div>';
            }
        },

        wellAssayRunningFormatter: function (cellValue, options) {
            if (cellValue !== '-') {
                return '<div class="cell-default assayRunningCell" data-row-id="' +
                    options.rowId + '">' + cellValue + '</div>';
            } else {
                return '<div class="cell-default" data-row-id="' +
                    options.rowId + '"></div>';
            }
        },

        wellAssayDoneFormatter: function (cellValue, options) {
            if (cellValue !== '-') {
                return '<div class="cell-default assayDoneCell" data-row-id="' +
                    options.rowId + '">' + cellValue + '</div>';
            } else {
                return '<div class="cell-default" data-row-id="' +
                    options.rowId + '"></div>';
            }
        },

        attachPopover: function () {
            this.$el.find('[data-content]').popover({
                trigger: 'hover',
                placement: 'bottom',
                html: 'true',
                container: 'body'
            });
        },

        attachTooltip: function () {

            this.ui.gridCellWithoutImage.tooltip({
                track: true,
                content: function () {
                    return _.i18n('common.click.here.for.detail');
                }
            });
        },

        onGridComplete: function () {
            if (this.filterEnable && this.setDefaultFiltersState) {
                this.setDefaultFiltersState();
            }
        },

        setDefaultFiltersState: function () {
            var postData = Settings.getFromMemory(this.findFilterName()),
                fillerValue;
            if (postData) {
                _.each(this.colModel, _.bind(function (column) {
                    if (column.search) {
                        fillerValue = postData[column.index];
                        if (column.stype === 'select' && fillerValue) {
                            this.$('select[name="' + column.index + '"]').val(fillerValue);
                        }
                    }
                }, this));
            }
        },

        triggerClear: function (e) {
            var $target = $(e.currentTarget);
            if ($target.is('input')) {
                if (!$target.val()) {
                    $target.trigger('clear');
                }
            } else {
                $target.closest('tr').find('.ui-search-input input').trigger('clear');
            }
        },

        codeNameFormatter: function (cellvalue) {
            if (cellvalue && cellvalue.code) {
                return cellvalue.code;
            } else {
                return '';
            }
        },

        mbAnaResGrFormatter: function (cellvalue) {
            if (cellvalue) {
                return '<span>' + cellvalue.code + '</span>';
            } else {
                return '';
            }
        },

        showBaseTableAddMenu: function (menuAddList) {
            if (menuAddList.hasClass('hiddedMenuAddList')) {
                menuAddList.addClass('visibleMenuAddList');
                menuAddList.removeClass('hiddedMenuAddList');
            } else {
                this.hideBaseTableAddMenu(menuAddList);
            }
        },

        hideBaseTableAddMenu: function (menuAddList) {
            menuAddList.addClass('hiddedMenuAddList');
            menuAddList.removeClass('visibleMenuAddList');
        },
        getCheckedRowsList: function () {
            var checkedRows = $('.cell-select.mdi-checkbox-marked-outline');

            var result = [];
            for (var i = 0; i < checkedRows.length; i++) {
                var rowId = $(checkedRows[i]).parent().find('span').attr('data-row-id');
                var currentCheckedRowData = this.ui.jqGrid.getRowData(rowId);
                result.push(currentCheckedRowData);
            }
            return result;
        },

        resetAllRowSelector: function () {
            if ($('.js-all-rows-selector').hasClass('mdi-checkbox-marked-outline')) {
                $('.js-all-rows-selector').removeClass('mdi-checkbox-marked-outline');
                $('.js-all-rows-selector').addClass('mdi-checkbox-blank-outline');
            }
        },

        checkAllRows: function (e) {
            var selectAll = false;
            if ($(e.target).hasClass('mdi-checkbox-blank-outline')) {
                $(e.target).removeClass('mdi-checkbox-blank-outline');
                $(e.target).addClass('mdi-checkbox-marked-outline');
                selectAll = true;

            } else if ($(e.target).hasClass('mdi-checkbox-marked-outline')) {
                $(e.target).removeClass('mdi-checkbox-marked-outline');
                $(e.target).addClass('mdi-checkbox-blank-outline');
            }

            _.each(this.ui.jqGrid.getRowData(), _.bind(function (row) {
                if (selectAll) {
                    this.$el.find('span.cell-select[data-row-id="' + row.secId + '"]').removeClass('mdi-checkbox-blank-outline');
                    this.$el.find('span.cell-select[data-row-id="' + row.secId + '"]').addClass('mdi-checkbox-marked-outline');
                } else {
                    this.$el.find('span.cell-select[data-row-id="' + row.secId + '"]').removeClass('mdi-checkbox-marked-outline');
                    this.$el.find('span.cell-select[data-row-id="' + row.secId + '"]').addClass('mdi-checkbox-blank-outline');
                }
            }, this));
        },

        checkOneRow: function (e) {
            if ($(e.target).hasClass('cell-select')) {
                if ($(e.target).hasClass('mdi-checkbox-blank-outline')) {
                    $(e.target).removeClass('mdi-checkbox-blank-outline');
                    $(e.target).addClass('mdi-checkbox-marked-outline');

                } else if ($(e.target).hasClass('mdi-checkbox-marked-outline')) {
                    $(e.target).removeClass('mdi-checkbox-marked-outline');
                    $(e.target).addClass('mdi-checkbox-blank-outline');
                }
            }
        },

        showExportFailNotification: function (result, errors, call, rowId) {
            var message = ': </br>';

            if (rowId) {
                if ($(this.ui.jqGrid.getRowData(rowId).lotId)) {
                    message = message + [$(this.ui.jqGrid.getRowData(rowId).lotId).text()];
                } else {
                    message = message + [$(this.ui.jqGrid.getRowData(rowId).code).text()];
                }

            } else if (errors) {
                for (var n = 0; n < errors.length; n++) {
                    if ($(errors[n].lotId)) {
                        message = message + '<li>' + $(errors[n].lotId).text() + '</li>';
                    } else {
                        message = message + '<li>' + $(errors[n].code).text() + '</li>';
                    }
                }
            }

            var params = {
                title: _.i18n('export.fail.notification.title'),
                message: _.i18n('export.fail.notification.message') + message,
                type: 'warning'
            };
            CustomBootboxMessage.customAlert(params);
        },

        exportElementsList: function (rowList, call, callBundled) {
            if (rowList.length === 1) {
                this.jqGridExport(rowList[0], call)
                    .done(_.bind(function (result) {
                        if (!result) {
                            this.showExportFailNotification(null, null, call, rowList[0].secId);
                        }
                    }, this));
            } else {
                this.jqGridMultiExport(rowList, call, callBundled);
            }
        },

        exportElement: function (event, call) {
            var rowId = $(event.currentTarget).find('span').attr('data-row-id');
            var row = this.ui.jqGrid.getRowData(rowId);

            if (row) {
                this.jqGridExport(row, call)
                    .done(_.bind(function (result) {
                        if (!result) {
                            this.showExportFailNotification(null, null, call, rowId);
                        }
                    }, this));
            }
        },

        duplicateElement: function (event, call) {
            var rowId = $(event.currentTarget).find('span').attr('data-row-id');
            var row = this.ui.jqGrid.getRowData(rowId);

            if (row) {
                this.jqGridDuplicate(row, call)
                    .done(_.bind(function (result) {
                        if (!result) {
                            this.showExportFailNotification(null, null, call, rowId);
                        }
                    }, this));
            }
        },

        jqGridMultiExport: function (rowList, call, callBundled) {
            Bootbox.dialog({
                title: _.i18n('export.multi.title'),
                message: _.i18n('export.multi.message'),
                size: 'large',
                backdrop: true,
                buttons: {
                    cancel: {
                        label: _.i18n('cancel'),
                        className: 'btn-danger'
                    },
                    CSV: {
                        label: _.i18n('export.CSV'),
                        className: 'btn-info',
                        callback: _.bind(function () {
                            // Bootbox.info(_.i18n('export.CSV.disabled'));
                            this._exportMulti(rowList, callBundled, 'csv', true)
                                .done(_.bind(function (errors) {
                                    if (errors && errors.length > 0) {
                                        this.showExportFailNotification(null, errors, call, null);
                                    }
                                }, this));
                        }, this)
                    },
                    JSON: {
                        label: _.i18n('export.JSON'),
                        className: 'btn-info',
                        callback: _.bind(function () {
                            this._exportMulti(rowList, call, 'json')
                                .done(_.bind(function (errors) {
                                    if (errors && errors.length > 0) {
                                        this.showExportFailNotification(null, errors, call, null);
                                    }
                                }, this));
                        }, this)
                    },
                    JSON_BUNDLED: {
                        label: _.i18n('export.JSON.bundled'),
                        className: 'btn-info',
                        callback: _.bind(function () {
                            this._exportMulti(rowList, callBundled, 'json', true)
                                .done(_.bind(function (errors) {
                                    if (errors && errors.length > 0) {
                                        this.showExportFailNotification(null, errors, callBundled, null);
                                    }
                                }, this));
                        }, this)
                    }
                }
            });
        },

        _exportMulti: function (rowList, call, type, bundled) {
            this.exportErrors = [];
            var defer = $.Deferred();

            if (bundled) {
                var promesse;
                if (typeof call === 'string') {
                    alert('Don\'t use string for bundled export, call is ' + call);
                    throw new Error('Don\'t use string for bundled export, call is ' + call);
                } else {
                    promesse = call(_.pluck(rowList, 'secId'), type);
                }
                promesse.done(_.bind(function (response) {
                    if (response.status && response.status !== 200) {
                        this.exportErrors.push(rowList);
                        defer.resolve(this.exportErrors);
                    } else {
                        var element = document.createElement('a');
                        element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(response.data));
                        element.setAttribute('download', response.fileName);
                        element.style.display = 'none';
                        document.body.appendChild(element);
                        element.click();
                        document.body.removeChild(element);
                        defer.resolve(this.exportErrors);
                    }
                }, this));
            } else {
                var lastRow = rowList[rowList.length - 1];
                _.each(rowList, _.bind(function (row) {
                    this.jqGridExport(row, call)
                        .done(_.bind(function (exportErrors) {
                            if (lastRow.secId === row.secId) {
                                defer.resolve(exportErrors);
                            }
                        }, this));
                }, this));
            }
            return defer.promise();
        },

        jqGridExport: function (row, call) {
            this.exportErrors = [];
            var defer = $.Deferred();
            var promesse;
            if (typeof call === 'string') {
                alert('Don\'t use string for export, call is ' + call);
                throw new Error('Don\'t use string for export, call is ' + call);
            } else {
                promesse = call(row.secId);
            }
            promesse.done(_.bind(function (response) {
                if (response.status && response.status !== 200) {
                    this.exportErrors.push(row);
                    defer.resolve(this.exportErrors);
                } else {
                    var element = document.createElement('a');
                    element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(response.data));
                    element.setAttribute('download', response.fileName);
                    element.style.display = 'none';
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                    defer.resolve(this.exportErrors);
                }
            }, this));
            return defer.promise();
        },

        jqGridDuplicate: function (row, call) {
            var defer = $.Deferred();
            var promesse;
            if (typeof call === 'string') {
                promesse = App.request(call, row.secId);
            } else {
                promesse = call(row.secId);
            }
            promesse.done(_.bind(function (response) {
                if (this.options.service && this.options.service.getModel) {
                    var model = this.options.service.getModel(response.imported);
                    require(['dynamicController'], _.bind(function (DynamicController) {
                        this.options.service.showDetails({
                            model: model,
                            controller: DynamicController,
                            callBackRefresh: _.bind(function () {
                                this.updatePagination('reloadGrid');
                            }, this),
                            service: this.options.service
                        });
                    }, this));
                }
            }, this));
            return defer.promise();
        },

        showDeleteFailNotification: function (result) {
            var message = ': </br>';

            _.each(result.failed, function (f) {
                message = message + '<li>' + f.code + '</li>';
            });

            var params = {
                title: _.i18n('delete.fail.notification.title'),
                message: _.i18n('delete.fail.notification.message') + message,
                type: 'warning'
            };
            CustomBootboxMessage.customAlert(params);
        },

        showArchiveRestoreFailNotification: function (result, archive) {
            var message = ': </br>';

            _.each(result.failed, function (f) {
                message = message + '<li>' + f.code + '</li>';
            });

            var params = {
                title: archive ? _.i18n('archive.fail.notification.title') : _.i18n('restore.fail.notification.title'),
                message: (archive ? _.i18n('archive.fail.notification.message') : _.i18n('restore.fail.notification.message')) + message,
                type: 'warning'
            };
            CustomBootboxMessage.customAlert(params);
        },

        jqGridDeleteByList: function (rowList, call) {
            var defer = $.Deferred();

            var secIds = [];
            _.each(rowList, function (row) {
                secIds.push(row.secId);
            });

            var promesse;
            if (typeof call === 'string') {
                alert('Don\'t use string for delete, call is ' + call);
                throw new Error('Don\'t use string for delete, call is ' + call);
            } else {
                promesse = call(secIds);
            }
            promesse
                .done(_.bind(function (result) {
                    if (result && result.failed && result.failed.length > 0) {
                        this.showDeleteFailNotification(result);
                    }
                }, this))
                .always(
                    _.bind(function () {
                        return defer.resolve();
                    }, this));
            return defer.promise();
        },

        jqGridArchiveRestoreByList: function (rowList, call, archive) {
            var defer = $.Deferred();

            var secIds = [];
            _.each(rowList, function (row) {
                secIds.push(row.secId);
            });

            var promesse;
            if (typeof call === 'string') {
                alert('Don\'t use string for archive, call is ' + call);
                throw new Error('Don\'t use string for archive, call is ' + call);
            } else {
                promesse = call(secIds);
            }
            promesse
                .done(_.bind(function (result) {
                    if (result.failed && result.failed.length > 0) {
                        this.showArchiveRestoreFailNotification(result, archive);
                    }
                }, this))
                .always(
                    _.bind(function () {
                        return defer.resolve();
                    }, this));
            return defer.promise();
        },

        jqGridHistoryBack: function () {
            window.history.go(-1); // window.history.back();
        }
    });
});

