define('services/caccounts/userpids',[
    'entities/ns',
    'jquery',
    'settings',
    'module',
    'dynamic',
    'underscore',
    'services/global/userpids',
    'require',
    'app',
    'backbone',
    'backboneRelational',
    'entities/caccounts/userpids'
], function (
    app,
    $,
    Settings,
    module,
    Dynamic,
    _,
    UserpidGlobal,
    requireJS,
    App,
    Backbone
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.UserPid,
                collection: app.ns.UserPidCollection
            };
        },
        getCreateEditFieldValidate: function () {
            return [
                {name: 'email', type: 'required'},
                {name: 'firstName', type: 'required'},
                {name: 'lastName', type: 'required'},
                {name: 'nickName', type: 'required'}
            ];
        },


        getDynamicJson: function () {
            return 'UserpidJson';
        },
        getTypeDynamicJson: function () {
            return 'CACCOUNT';
        },
        getUrl: function () {
            return 'rest/v2/userpid/';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.exportable = false;
            config.archivable = false;
            config.selectArchivable = false;
            config.importable = false;
            config.attachmentable = false;
            config.duplicable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: 'SU',
                name: 'superUser',
                classes: 'dynamic-link',
                formatter: viewDynamic.booleanFormatterNoCheckBox,
                search: false,
                index: 'superUser',
                width: 5
            }, {
                label: _.i18n('userpid.lastName'),
                name: 'lastName',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'lastName',
                width: 40
            }, {
                label: _.i18n('userpid.firstName'),
                name: 'firstName',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'firstName',
                width: 40
            }, {
                label: _.i18n('userpid.logonId'),
                name: 'logonId',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'logonId',
                width: 50
            }, {
                label: _.i18n('userpid.nickName'),
                name: 'nickName',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'nickName',
                width: 20
            }, {
                label: _.i18n('userpid.email'),
                name: 'email',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'email',
                width: 50
            }, {
                label: _.i18n('userpid.userGroup'),
                name: 'userGroup',
                classes: 'dynamic-link',
                formatter: viewDynamic.codeNameFormatter,
                sortable: false,
                search: false,
                index: 'userGroup',
                width: 20
            }, {
                label: _.i18n('userpid.authorityGroups'),
                name: 'authorityGroups',
                classes: 'dynamic-link',
                formatter: viewDynamic.codeNameListFormatter,
                sortable: false,
                search: false,
                index: 'type',
                width: 20
            });

            if (Settings.get('currentUserModel').hasRole('SUPER_USER')) {
                columns.push({
                    label: '',
                    name: '',
                    classes: 'dynamic-link',
                    formatter: this.loginToFormatter,
                    search: false,
                    width: 20,
                    onClick: _.bind(function (rowId) {
                        $('.js-global-loader').show();
                        $.ajax({
                            type: 'get',
                            url: Settings.url('rest/v2/userpid/loginTo/' + rowId),
                            contentType: 'application/json',
                            success: function () {
                                Settings.set('loginTo', true);
                                location.reload(true);
                            },
                            error: function () {
                                $('.js-global-loader').hide();
                                App.alert('Cannot switch user', _.i18n('error'), _.i18n('error'));
                            }
                        });
                    }, this)
                });
            }
            return columns;
        },

        loginToFormatter: function (cellValue, options) {
            if (options.rowId === Settings.get('currentUserModel').get('secId')) {
                return '';
            }
            return '<span class="mdi mdi-account-switch-outline js-icon" ' +
                'title="' + _.i18n('switch.account') + '" data-row-id="' + options.rowId + '"></span>';
        },

        getAutocompleteParam: function (config) {
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.values = {'sord': 'asc', 'sidx': 'nickName'};
            return configuration;
        },
        getByGlobal: function (secIdGlobalUser) {
            var url = Settings.url('rest/v2/userpid/byGlobal/' + secIdGlobalUser),
                defer = $.Deferred();
            $.ajax({
                method: 'GET',
                url: url,
                contentType: 'application/json',
                success: _.bind(function (json) {
                    defer.resolve(this.getModel(json));
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },

        getListConfiguration: function () {
            var defer = $.Deferred();
            var configuration = Dynamic.getListConfiguration.call(this);
            configuration.done(function (resp) {
                var authorityGroupService = require('services/caccounts/authorityGroup');
                resp.push({fieldName: 'authorityGroups', values: authorityGroupService.findAll()});
                defer.resolve(resp);
            });
            return defer.promise();
        },
        getCurrentUser: function () {
            var defer = $.Deferred();

            var url = Settings.url('rest/v2/userpid/currentUser');
            $.ajax({
                url: url,
                success: function (data) {
                    defer.resolve(data);
                }
            });

            return defer.promise();
        },
        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore.call(this);
            ignored.push('logo');
            ignored.push('dependAuthorities');
            ignored.push('superUser');
            return ignored;
        },
        getCreateEditFieldCustom: function (model) {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push({
                'field': 'firstName',
                'param': {
                    'type': 'STRING',
                    'display': {
                        'class': 'col-xs-3'
                    }
                }
            });
            custom.push({
                'field': 'lastName',
                'param': {
                    'type': 'STRING',
                    'display': {
                        'class': 'col-xs-3'
                    }
                }
            });
            custom.push({
                'field': 'email',
                'param': {
                    'type': 'STRING',
                    'display': {
                        'class': 'col-xs-5',
                        attributes: [{
                            type: 'email'
                        }]
                    }
                }
            });
            custom.push({
                'field': 'nickName',
                'param': {
                    'type': 'STRING',
                    'display': {
                        'class': 'col-xs-2 m-b-20',
                        attributes: [{
                            maxlength: 6,
                            size: 6,
                            style: 'width: 55px;'
                        }]
                    }
                }
            });
            custom.push({
                'field': 'userGroup',
                'param': {
                    'type': 'REFERENCE',
                    'display': {
                        'class': 'col-xs-3'
                    }
                }
            });
            custom.push({
                'field': 'authorityGroup',
                'param': {
                    'type': 'BUTTON',
                    display: {
                        'text': _.i18n('authorityGroup.userpid')
                    },
                    'events': {
                        'click': _.bind(function () {
                            var authorityGroupModel = model.get('authorityGroup');
                            var authorityGroupService = require('services/caccounts/authorityGroup');
                            if (authorityGroupModel === null) {
                                authorityGroupModel = authorityGroupService.getModel({
                                    code: 'USER_' + model.get('secId').split('_')[0],
                                    name: 'Custom authority for ' + model.get('lastName') + ' ' + model.get('firstName'),
                                    privileges: [],
                                    'userpid': model
                                });
                                model.set({authorityGroup: authorityGroupModel}, {silent: true});
                            }
                            authorityGroupService.showDetails({
                                service: authorityGroupService,
                                model: authorityGroupModel,
                                disableCodeName: true,
                                title: _.i18n('userpid.additionalAuthorities'),
                                callBackOnClose: _.bind(function () {
                                    if (authorityGroupModel.isNew()) {
                                        Backbone.Relational.store.unregister(authorityGroupModel);
                                    }
                                }, this)
                            });
                        }, this)
                    }
                }
            });
            custom.push({
                'field': 'authorityGroups',
                'param': {
                    'type': 'LISTTOLIST'
                }
            });
            custom.push({
                'field': 'authorities',
                'param': {
                    'type': 'POPUP',
                    'display': {
                        'class': 'col-w-5',
                        'mdi': 'mdi-eye'
                    },
                    'events': {
                        'click': _.bind(function () {
                            var authorityGroupService = require('services/caccounts/authorityGroup');
                            var obj = {
                                secId: model.get(model.idAttribute) + '_USERPID'
                            };
                            obj.privileges = model.get('authorityGroups').chain().map(function (authorityGroup) {
                                return authorityGroup.get('privileges');
                            }).flatten().uniq().value();
                            if (model.get('userGroup')) {
                                obj.privileges = obj.privileges.concat(model.get('userGroup').get('authorityGroups').chain()
                                    .map(function (authorityGroup) {
                                        return authorityGroup.get('privileges');
                                    }).flatten().uniq().value());
                            }
                            if (model.get('authorityGroup') && model.get('authorityGroup').get('privileges')) {
                                obj.privileges = obj.privileges.concat(model.get('authorityGroup').get('privileges'));
                            }
                            var authorityGroupModel = authorityGroupService.getModel(obj);
                            requireJS(['roleController'], function (Controller) {
                                Controller.showDetails({model: authorityGroupModel, readOnly: true, title: model.toString()});
                            });
                        }, this)
                    }
                }
            });
            return custom;
        },
        getList: function (fieldName) {
            var defer = $.Deferred();
            switch (fieldName) {
                case 'userGroups':
                    var UserGroupService = require('services/caccounts/userGroup');
                    UserGroupService.findAll().done(function (userpids) {
                        defer.resolve({items: userpids});
                    });
                    break;
                case 'authorityGroups':
                    var AuthorityGroupService = require('services/caccounts/authorityGroup');
                    AuthorityGroupService.findAll().done(function (authorityGroups) {
                        defer.resolve({items: authorityGroups});
                    });
                    break;
            }
            return defer.promise();
        },
        logout: function () {
            $('.js-global-loader').show();
            $.ajax({
                type: 'get',
                url: Settings.url('rest/v2/userpid/logout'),
                contentType: 'application/json',
                success: function () {
                    Settings.set('loginTo', null);
                    location.reload(true);
                },
                error: function () {
                    $('.js-global-loader').hide();
                    App.alert('Cannot logout user', _.i18n('error'), _.i18n('error'));
                }
            });
        }
    }, UserpidGlobal);

});

