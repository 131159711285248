require([
    'jquery',
    'app',
    'underscore',
    'settings',
    'logger',

    // grid
    'jqgridBase/js/grid.base',
    'jqgridBase/js/grid.celledit',
    'jqgridBase/js/grid.common',
    'jqgridBase/js/grid.export',
    'jqgridBase/js/grid.filter',
    'jqgridBase/js/grid.formedit',
    'jqgridBase/js/grid.grouping',
    'jqgridBase/js/grid.import',
    'jqgridBase/js/grid.inlinedit',
    'jqgridBase/js/grid.jqueryui',
    'jqgridBase/js/grid.pivot',
    'jqgridBase/js/grid.subgrid',
    'jqgridBase/js/grid.treegrid',
    'jqgridBase/js/jqDnR',
    'jqgridBase/js/jqModal',
    'jqgridBase/js/jquery.fmatter',
    'jqgridBase/js/grid.utils',
    'jqgridBase/js/addons/ui.multiselect',
    'jqgridBase/js/i18n/grid.locale-en'
], function ($, App, _, Settings, Logger) {
    'use strict';
    if (!$.jgrid.hasOwnProperty('regional')) {
        $.jgrid.regional = {};
    }

    require(['jqgridBase/plugins/grid.postext'], function () {

        $.jgrid.regional.labels = {
            defaults: {
                recordtext: _.i18n('jqgrid.view') + ' {0} - {1} ' + _.i18n('jqgrid.of') + ' {2}',
                emptyrecords: _.i18n('jqgrid.emptyrecords'),
                loadtext: _.i18n('jqgrid.loadtext'),
                pgtext: _.i18n('jqgrid.pgtext') + ' {0} ' + _.i18n('jqgrid.of') + ' {1}',
                savetext: _.i18n('jqgrid.savetext'),
                pgfirst: _.i18n('jqgrid.pgfirst'),
                pglast: _.i18n('jqgrid.pglast'),
                pgnext: _.i18n('jqgrid.pgnext'),
                pgprev: _.i18n('jqgrid.pgprev'),
                pgrecs: _.i18n('jqgrid.pgrecs')
            },
            search: {
                resetTitle: _.i18n('jqgrid.resetTitle')
            },
            formatter: {
                integer: {thousandsSeparator: ',', defaultValue: '0'}
            }
        };

        $.extend($.jgrid, $.jgrid.regional.labels);
        Logger.log('jqGrid loaded! in  local ' + Settings.get('lang'));
        App.trigger('jqGrid:loaded');
        Settings.jqGridLoaded = true;
    });
});
define("jqgrid", ["jqueryui"], function(){});

