define('pcrWellToolTipView',[
    'module',
    'entities/ns',
    'backbone.marionette',
    'backbone',
    'underscore',
    'template!pcrWellToolTipView',
    'pcrWellToolTipDotCollectionView',
    'pcrWellResultController'
], function (
    module,
    app,
    Marionette,
    Backbone,
    _,
    Tpl,
    PcrWellToolTipDotCollectionView,
    PcrWellResultController
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,

        regions: {
            results: '.js-results'
        },

        className: 'col-w-all col-w-100 d-f',
        attributes: {
            style: 'color: white;'
        },

        serializeData: function () {
            var templateData = {
                pos: this.model.get('pos'),
                assay: this.model.get('refAssay') ? this.model.get('refAssay').get('code') : '',
                valWst: this.model.get('valWst'),
                repeatStatus: this.model.get('repeatStatus'),
                smpType: this.model.get('smpType') ? this.model.get('smpType') : '',
                pooled: this.model.get('pooled'),
                smpId: this.model.get('smpId') ? this.model.get('smpId').get('code') : ''
            };
            templateData.errorWarning = this.model.get('results').any(_.bind(function (wellTarget) {
                return wellTarget.get('codeErr') !== null && wellTarget.get('codeErr') !== undefined;
            }, this));
            templateData.concatComment = '';
            if (this.model.get('smpId').get('lisComment')) {
                templateData.concatComment = this.model.get('smpId').get('lisComment') + ' ';
            }
            if (this.model.get('smpId').get('comment')) {
                templateData.concatComment = templateData.concatComment + this.model.get('smpId').get('comment');
            }
            templateData.wellsPooled = this.options.wellsPooled;
            return templateData;
        },

        onRender: function () {
            var run;
            if (this.model instanceof app.ns.PcrWell) {
                run = this.model.get('run');
            } else if (this.model instanceof app.ns.PcrWellResult) {
                run = this.model.get('refPcrWell').get('run');
            } else if (this.model instanceof Backbone.Collection && this.model.first() instanceof app.ns.PcrWellResult) {
                run = this.model.first().get('refPcrWell').get('run');
            } else {
                throw new Error('Unknown model type');
            }
            if (!run) {
                console.error('No run found for model ' + this.model.get('secId'));
                return;
            }
            if (!run.get('refKitProt')) {
                console.error('No kit protocol found for pcr run ' + run.get('code') + ' (' + run.get('secId') + ')');
                return;
            }
            var displayMode = run.get('displayMode');

            if (!displayMode) {
                var DisplayModeService = require('services/caccounts/displayModes');
                displayMode = DisplayModeService.getDefaultDisplayMode();
            }

            var template;
            switch (displayMode.get('displayTooltip')) {
                case 'HISTOGRAM':
                    template = '<div class="row js-histogram m-b-10"></div>';
                    break;
                case 'LIST':
                    template = '<div class="row js-list m-b-10"></div>';
                    break;
                case 'HISTOGRAM_LIST':
                    template = '<div class="row js-histogram m-b-10"></div><div class="row js-list m-b-10"></div>';
                    break;
                case 'LIST_HISTOGRAM':
                    template = '<div class="row js-list m-b-10"></div><div class="row js-histogram m-b-10"></div>';
                    break;
            }
            var collectionForHistogram = new Backbone.Collection(this.model.get('results').chain().filter(function (result) {
                return result.get('refAssayResult') && result.get('refAssayResult').get('sequenceDisplay');
            }).sortBy(function (result) {
                return result.get('refAssayResult').get('sequenceDisplay');
            }).value());
            var collectionForDot = new Backbone.Collection(this.model.get('results').chain().filter(function (result) {
                return result.get('refAssayResult') && result.get('refAssayResult').get('sequenceDisplay');
            }).sortBy(function (result) {
                return result.get('refAssayResult').get('sequenceDisplay');
            }).value());
            var config = displayMode.findDotForTooltip();
            if (config.get('parameter') && config.get('parameter').type === 'DOT') {
                var paramSplitted = config.get('parameter').param.split(';');
                var includePositive = false;
                if (paramSplitted.length > 0) {
                    includePositive = paramSplitted[0] === 'true';
                }
                if (includePositive) {
                    this.model.get('results').chain().filter(function (result) {
                        return result.get('result').get('code') === 'P';
                    }).sortBy(function (result) {
                        return result.get('refAssayResult').get('sequenceDisplay');
                    }).forEach(function (result) {
                        collectionForDot.push(result);
                    });
                }
            }

            var content;
            var histogram = new Marionette.View({
                template: _.template(template),
                regions: {
                    histogram: '.js-histogram',
                    list: '.js-list'
                },
                onRender: function () {
                    switch (displayMode.get('displayTooltip')) {
                        case 'HISTOGRAM':
                            content = PcrWellResultController.generateContent(collectionForHistogram, 'Tooltip');
                            this.getRegion('histogram').show(new Marionette.View({template: _.template(content)}));
                            break;
                        case 'LIST':
                            this.getRegion('list').show(new PcrWellToolTipDotCollectionView({
                                collection: collectionForDot
                            }));
                            break;
                        case 'HISTOGRAM_LIST':
                        case 'LIST_HISTOGRAM':
                            content = PcrWellResultController.generateContent(collectionForHistogram, 'Tooltip');
                            this.getRegion('histogram').show(new Marionette.View({template: _.template(content)}));
                            this.getRegion('list').show(new PcrWellToolTipDotCollectionView({
                                collection: collectionForDot
                            }));
                            break;
                    }
                }
            });

            this.getRegion('results').show(histogram);
        }
    });
});

