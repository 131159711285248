/**
 * Created by Matteo on 19/07/2015.
 */
define('router',[
    'require',
    'jquery',
    'module',
    'logger',
    'settings',
    'marionetteAppRouter',
    'app',
    'bootbox',
    'underscore',
    'loginMain',
    'autoLoginMain',
    'registerMain',
    'forgotMain',
    'resetMain',
    'thankYouMain',
    'selectCaccountMain',
    'contentMain',
    'headerMain',
    'sidebarMain',
    'superAdminMain',
    'pluginMain',
    'pcrSetupMain',
    'caccountsMain',
    'routingMain',
    'runsMain',
    'prepRunsMain',
    'adminMain'
], function (
    require,
    $,
    module,
    Logger,
    Settings,
    AppRouter,
    App,
    bootbox,
    _
) {
    'use strict';

    var Router = AppRouter.extend({
        appRoutes: {
            '': 'showIndex',
            'switchCAccount': 'showSwitchCAccount',
            'unauthorized': 'showUnauthorized'
        },

        initialize: function () {
            $(window).ajaxError(function (event, request, settings) {
                switch (request.status) {
                    case 204:
                        bootbox.alert(_.i18n('method.noContent') + '<br/><br/>' + request.responseText);
                        break;
                    //User doesn't have the correct roles
                    case 403:
                        Settings.set('lastUrlPageCookie', null);
                        App.trigger('unauthorized:show', request.responseText);
                        break;
                    //The session has expired
                    case 401:
                        if (!App.getCurrentRoute().startsWith('reset_password')) {
                            Settings.set('lastUrlPageCookie', App.getCurrentRoute());
                            App.clearAuthCookieValues();
                            App.trigger('login:show', _.i18n('sessionExpired'));
                        }
                        break;
                    case 405:
                        var parsedUrl = new URL(settings.url);
                        var path = parsedUrl.pathname;
                        var cleanPath = path.startsWith('/') ? path.slice(1) : path;
                        bootbox.dialog({
                            title: _.i18n('method.notAllowed'),
                            message:
                                '<div class="mdi mdi-shield-lock-outline"></div>' +
                                '<h2>Error-ID</h2>' +
                                '<div class="error-id">' + settings.type + ' : ' + cleanPath + '</div>' +
                                '<div class="information">' +
                                '<label>Account :</label><div>' + Settings.get('currentCAccount').code + '</div>' +
                                '<label>User :</label><div>' + Settings.get('currentUserModel').toString() + ' (login : ' + Settings.get('currentUserModel').get('logonId') + ')</div>' +
                                '<label>Date :</label><div>' + new Date().toLocaleString() + '</div>' +
                                '</div>',
                            className: 'not-allowed-dialog'
                        });
                        break;
                    case 500:
                        bootbox.alert(_.i18n('internalServerError') + '<br/><br/>' + request.responseText);
                        break;
                    case 503:
                        window.location.href = Settings.serverUrl + 'server-unavailable.html';
                        break;
                    default:
                        Logger.log(arguments);
                }
            });
        }
    });

    var API = {
        showUnauthorized: function (message) {
            require(['unauthorizedShowController'], function (ShowController) {
                ShowController.showUnauthorized(message);
            });
        },

        showIndex: function () {
            require(
                ['contentShowController', 'indexController'],
                function (ContentShowController, IndexController) {
                    ContentShowController.showContent().done(function () {
                        IndexController.show();
                    }).fail(function () {
                        App.trigger('login:show');
                    });
                }
            );
        },
        showSwitchCAccount: function () {
            App.navigate('', {trigger: true});
        }
    };

    App.on('unauthorized:show', function (message) {
        App.navigate('unauthorized');
        API.showUnauthorized(message);
    });

    App.on('index:show', function () {
        App.navigate('');
        API.showIndex();
    });

    App.on('before:start', function () {
        new Router({
            controller: API
        });
    });

    module.exports = Router;
});

