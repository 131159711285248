define('entities/caccounts/pcrruns',[
    'entities/ns',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'moment',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/curves',
    'entities/caccounts/assay',
    'entities/caccounts/assayVersion',
    'entities/caccounts/pcrwells',
    'entities/caccounts/cyclers',
    'entities/caccounts/kitprots',
    'entities/caccounts/displayModes',
    'entities/caccounts/userpids',
    'entities/admin/dye'
], function (
    app,
    Backbone,
    Settings,
    $,
    _,
    moment,
    RolesMixin
) {
    'use strict';

    app.ns.PcrRun = Backbone.RelationalModel.extend({
        service: 'services/caccounts/pcrruns',
        fetch: function () {
            this.url = Settings.url('rest/v2/runs/pcr/' + this.get('secId'));
            var defer = $.Deferred();
            Backbone.Model.prototype.fetch.call(this);
            this.once('sync', _.bind(function () {
                this.get('wells').fetch().always(function () {
                    defer.resolve();
                });
            }, this));
            return defer.promise();
        },

        defaults: {
            'name': '',
            'creatDate': null,
            'modDate': null,
            'plateSize': 'H12',
            'comment': '',
            'wells': [],
            'refModUser': {},
            'refCreatUser': {},
            'refRespUser': {},
            'settings': {},
            'toBePooled': true,
            'period': '',
            'periodFormatted': '',
            'group': '',
            'sequence': 0,
            'sequenceFormat': '',
            'pattern': '',
            'suffix': '',
            'assays': [],
            'plateIdPC': '',
            'refLmbCycler': null,
            'refKitProt': null,
            'status': 0,
            'valStatus': '',
            'exported': true,
            'type': '',
            'department': '',
            'archived': false,
            'archivedDate': '',
            'prepRuns': [],
            'hasColorCompensation': false,
            'assayVersions': [],
            'displayMode': null
        },
        idAttribute: 'secId',

        relations: [{
            type: Backbone.HasMany,
            key: 'wells',
            relatedModel: 'PcrWell',
            collectionType: 'PcrWellCollection',
            reverseRelation: {
                key: 'run'
            }
        }, {
            type: Backbone.HasMany,
            key: 'prepRuns',
            relatedModel: 'PrepRun',
            collectionType: 'PrepRunCollection'
        }, {
            type: Backbone.HasMany,
            key: 'assays',
            relatedModel: 'Assay',
            collectionType: 'AssayCollection'
        }, {
            type: Backbone.HasMany,
            key: 'assayVersions',
            relatedModel: 'AssayVersion',
            collectionType: 'AssayVersionCollection'
        }, {
            type: Backbone.HasOne,
            key: 'refCycler',
            relatedModel: 'Cycler'
        }, {
            type: Backbone.HasOne,
            key: 'refCyclerPublic',
            relatedModel: 'CyclerPublic'
        }, {
            type: Backbone.HasOne,
            key: 'refKitProt',
            relatedModel: 'KitProt'
        }, {
            type: Backbone.HasOne,
            key: 'refRespUser',
            relatedModel: 'UserPid'
        }, {
            type: Backbone.HasOne,
            key: 'displayMode',
            relatedModel: 'DisplayMode'
        }, {
            type: Backbone.HasMany,
            key: 'dyes',
            relatedModel: 'Dye',
            collectionType: 'DyeCollection'
        }],
        getUrl: function () {
            return 'rest/v2/runs/pcr/';
        },

        getType: function () {
            return 'PcrRun';
        },

        getLine: function () {
            return this.get('plateSize').substring(0, 1);
        },
        getColumn: function () {
            return parseInt(this.get('plateSize').substring(1), 10);
        },

        postUrl: function () {
            return Settings.url('rest/v2/runs/pcr/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        saveHeader: function () {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/header'),
                defer = $.Deferred();

            var rubKitProtSecId = this.get('refKitProt') && this.get('refKitProt').get('secId') ? this.get('refKitProt').get('secId') : null;
            var runLmbCyclerSecId = this.get('refLmbCycler') && this.get('refLmbCycler').secId ? this.get('refLmbCycler').secId : null;
            var cyclerSecId = this.get('refCycler') && this.get('refCycler').get('secId') ? this.get('refCycler').get('secId') : null;
            var cyclerPublicSecId = this.get('refCyclerPublic') && this.get('refCyclerPublic').secId ? this.get('refCyclerPublic').secId : null;
            var refRespUserSecId = this.get('refRespUser') && this.get('refRespUser').secId ? this.get('refRespUser').secId : null;

            var body = {
                name: this.get('name'),
                status: this.get('status'),
                period: this.get('period'),
                department: this.get('department'),
                group: this.get('group'),
                sequence: this.get('sequence'),
                suffix: this.get('suffix'),
                pattern: this.get('pattern'),
                plateId: this.get('plateIdPC'),
                kitProtSecId: rubKitProtSecId,
                lmbCyclerSecId: runLmbCyclerSecId,
                comment: this.get('comment'),
                respUserSecId: refRespUserSecId,
                cyclerSecId: cyclerSecId,
                cyclerPublicSecId: cyclerPublicSecId
            };

            $.ajax({
                url: url,
                type: 'PUT',
                data: JSON.stringify(body),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        copyPlateLayoutFrom: function (model) {
            var request = {
                sourceSecId: model.get('sourceRun').get('secId'),
                sourceStart: model.get('wellSelection').get('start'),
                sourceEnd: model.get('wellSelection').get('to'),
                sourcePlateFillDirection: model.get('wellSelection').get('direction'),
                copySampleType: model.get('copySampleType'),
                copySampleId: model.get('copySampleId'),
                copyAssay: model.get('copyAssay'),
                targetStart: model.get('wellTarget').get('start'),
                targetPlateFillDirection: model.get('wellTarget').get('direction')
            };
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/copyPlateLayoutFrom'),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'PUT',
                contentType: 'application/json',
                data: JSON.stringify(request),
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: _.bind(function (err) {
                    this.fetch().done(function () {
                        defer.reject(err);
                    });
                }, this)
            });
            return defer.promise();
        },

        // import result
        updateWithXmlFile: function (files, params) {
            var defer = $.Deferred(),
                formdata = new FormData(),
                url = Settings.url('rest/v2/runs/pcr/' + this.get('secId'), params);

            _.each(files, function (file) {
                formdata.append('file', file);
            });


            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                timeout: 300000,
                processData: false,
                contentType: false,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        // import plate Layout
        importFilePlateLayout: function (model) {
            var param;

            param = {
                fileFormatSecId: model.get('fileFormat').get('secId')
            };

            var defer = $.Deferred(),
                formdata = new FormData(),
                url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/plateLayout/import', param);

            formdata.append('file', model.get('files')[0]);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        // import sample list
        importFileSampleList: function (model) {
            var param = {
                fileFormatSecId: model.get('fileFormat').get('secId'),
                startPos: model.get('wellSelection').get('start'),
                plateFillDirection: model.get('wellSelection').get('direction')
            };

            var defer = $.Deferred(),
                formdata = new FormData(),
                url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/sampleList/import', param);

            formdata.append('file', model.get('files')[0]);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        exportFilePlateLayout: function (model) {
            var defer = $.Deferred();
            var url;
            if (model.get('fileFormat')) {
                url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/plateLayout/export', {
                    fileFormatSecId: model.get('fileFormat').get('secId'),
                    downloadFile: model.get('downloadFile')
                });
            } else {
                url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/plateLayout/exportByCycler', {
                    downloadFile: model.get('downloadFile')
                });
            }
            if (model.get('downloadFile')) {
                this._downloadFile(url);
                defer.resolve(false);
            } else {
                $.ajax({
                    type: 'GET',
                    url: url,
                    success: function () {
                        defer.resolve(true);
                    },
                    error: function (error) {
                        defer.reject(error);
                    }
                });
            }
            return defer.promise();
        },

        exportFileSampleList: function (model) {
            var defer = $.Deferred();
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/sampleList/export', {
                fileFormatSecId: model.get('fileFormat').get('secId'),
                downloadFile: model.get('downloadFile')
            });

            if (model.get('downloadFile')) {
                this._downloadFile(url);
                defer.resolve(false);
            } else {
                $.ajax({
                    type: 'GET',
                    url: url,
                    success: function () {
                        defer.resolve(true);
                    },
                    error: function (error) {
                        defer.reject(error);
                    }
                });
            }
            return defer.promise();
        },


        // import sample list
        importResult: function (model) {
            var param = {
                cyclerSecId: model.get('cycler').get('secId'),
                userpidSecId: model.get('respUser').get('secId')
            };

            var defer = $.Deferred(),
                formdata = new FormData(),
                url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/result/import', param);

            formdata.append('file', model.get('files')[0]);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        _downloadFile: function (url) {
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'blob';

            xhr.onload = function () {
                if (xhr.status === 200) {
                    // Extraire le nom de fichier du header Content-Disposition
                    var disposition = xhr.getResponseHeader('Content-Disposition');
                    var matches = /filename="?([^"]+)"?;?/i.exec(disposition);
                    var filename = matches !== null && matches.length > 1 ? matches[1] : 'file';

                    // Créer un lien de téléchargement
                    var blob = new Blob([xhr.response], {type: 'application/octet-stream'});
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = filename;

                    // Déclencher le téléchargement
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            };

            xhr.send();
        },


        getRunAtFromTarget: function (targetSecId) {
            console.error('%cPlease check pcrRuns.getRunAtFromTarget()"', 'color: #00f8ff');
            var runAt = _.find(this.get('runAts'), function (runAt) {
                return (runAt.refKitP.secId === targetSecId);
            });
            return runAt;
        },

        exportResult: function (json) {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/export/result'),
                defer = $.Deferred();

            $.ajax({
                method: 'post',
                url: url,
                data: json ? JSON.stringify(json) : null,
                contentType: 'application/json',
                dataType: 'json',
                success: function (response) {
                    defer.resolve(response);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        validation2: function () {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/validation2'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                contentType: 'application/json',
                success: _.bind(function (response) {
                    this.fetch().done(function () {
                        defer.resolve(response);
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        exportList: function (type) {
            var params = {
                type: type
            };

            var url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/export/list', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (response) {
                    defer.resolve(response);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },

        extractOrderIdFromSampleId: function (sampleId) {
            var sidOrderId = this.get('settings').SID;

            if (sidOrderId && sampleId) {
                var orderIdIsIncludedInSid = sidOrderId.split(';')[7];
                var orderIdStartPos = sidOrderId.split(';')[8];
                var orderIdEndPos = sidOrderId.split(';')[9];

                if (orderIdIsIncludedInSid === 'Y') {
                    sampleId = sampleId.substr(Number(orderIdStartPos) > 0 ? Number(orderIdStartPos) - 1 : Number(orderIdStartPos),
                        Number(orderIdEndPos));
                }
            }
            return sampleId;
        },

        getGeneratePeriodFormatted: function (int64Date) {
            var params = {
                period: int64Date
            };
            var url = Settings.url('rest/v2/runs/pcr/generatePeriodFormatted', params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        availableRunName: function () {
            var params = {
                runName: this.get('name')
            };

            var url = Settings.url('rest/v2/runs/pcr/availableRunName', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        generateRunName: function () {
            var params = {
                pattern: this.get('pattern'),
                department: this.get('department'),
                group: this.get('group'),
                period: this.get('period') ? Number(moment(this.get('period')).format('x')) : null,
                sequenceFormat: this.get('sequenceFormat'),
                sequence: this.get('sequence'),
                suffix: this.get('suffix')
            };
            var url = Settings.url('rest/v2/runs/pcr/generateRunName', params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: _.bind(function (resp) {
                    // this.set('name', resp);
                    defer.resolve(resp);
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        refreshData: function () {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/refreshResult'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },

        reloadCurves: function () {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/reloadCurves'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },

        applyAssayWells: function (model) {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/wells/assay/assign', {
                assaySecId: model.get('assay').get('secId'),
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'PUT',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        replaceAssayWells: function (model) {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/wells/assay/replace', {
                assayReplaceSecId: model.get('assay').get('secId'),
                assayNewSecId: model.get('newAssay').get('secId'),
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'PUT',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        applyTestWells: function (model) {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/wells/test/add', {
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        clearAssay: function (model) {
            var params = {
                assaySecId: model.get('assay').get('secId'),
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            };

            var url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/wells/assay/clear', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        removeTest: function (model) {
            var params = {
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            };

            var url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/wells/test/remove', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        clearWells: function (model) {
            var params = {
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            };

            var url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/wells/clear', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        generateSampleId: function (sampleIds) {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/wells/generateSampleId'),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'PUT',
                contentType: 'application/json',
                data: JSON.stringify(sampleIds),
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        clearSampleId: function (model) {
            var params = {
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            };

            var url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/wells/clearSampleId', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },
        getPreviousRun: function () {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/previous'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function (resp) {
                    defer.resolve(resp);
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        getNextRun: function () {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/next'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function (resp) {
                    defer.resolve(resp);
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        controlSample: function () {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/samples/control'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (controlSampleResponse) {
                    defer.resolve(controlSampleResponse);
                },
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        startScanning: function () {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/scanning/start'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    defer.resolve();
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        stopScanning: function () {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/scanning/stop'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this)
            });
            return defer.promise();
        },

        findSelectedModel: function (posSelected) {
            var wellSecId = [];
            _.each(this.get('wells').filter(function (model) {
                return posSelected.includes(model.get('pos'));
            }), function (model) {
                wellSecId.push(model.get('secId'));
            });
            return wellSecId;
        }, /**
         * Validation 1 for specific target on specific wells
         * @param {Array} wellSecId
         * @param {Object} assayResult
         * @returns {*|jQuery}
         */
        validationWells: function (wellSecId, assayResult) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/wells/validation/' + assayResult.get('id'));
            return this.callAction(defer, url, wellSecId);
        },

        callAction: function (defer, url, wellSecId) {
            wellSecId = JSON.stringify(wellSecId.map(function (well) {
                return well.get('secId');
            }));
            $.ajax({
                url: url,
                type: 'PUT',
                data: wellSecId,
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Validation 2 for specific target on specific wells
         * @param {Array} wellSecId
         * @param {Object} assayResult
         * @returns {*|jQuery}
         */
        validation2Wells: function (wellSecId, assayResult) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/wells/validation2/' + assayResult.get('id'));

            return this.callAction(defer, url, wellSecId);
        },

        /**
         * Validation 12 for specific target on specific wells
         * @param {Array} wellSecId
         * @param {Object} assayResult
         * @returns {*|jQuery}
         */
        validation12Wells: function (wellSecId, assayResult) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/wells/validation12/' + assayResult.get('id'));

            return this.callAction(defer, url, wellSecId);
        },

        /**
         * Negative result for specific target on specific wells
         * @param {Array} wellSecId
         * @param {Object} assayResult
         * @returns {*|jQuery}
         */
        negativeWells: function (wellSecId, assayResult) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/wells/negative/' + assayResult.get('id'));

            return this.callAction(defer, url, wellSecId);
        },

        /**
         * Repeat PCR technical for specific target on specific wells
         * @param {Array} wellSecId
         * @returns {*|jQuery}
         */
        repeatPCRWells: function (wellSecId) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/wells/repeat/PT');

            return this.callAction(defer, url, wellSecId);
        },

        /**
         * Repeat PCR control for specific target on specific wells
         * @param {Array} wellSecId
         * @returns {*|jQuery}
         */
        repeatPCRControlWells: function (wellSecId) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/wells/repeat/PC');

            return this.callAction(defer, url, wellSecId);
        },
        /**
         * Repeat PCR technical for specific target on specific wells
         * @param {Array} wellSecId
         * @param {String} dillution
         * @returns {*|jQuery}
         */
        repeatExtractionWells: function (wellSecId, dillution) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/wells/repeat/XT', {'dillution': dillution});

            return this.callAction(defer, url, wellSecId);
        },

        /**
         * Repeat Extraction control for specific target on specific wells
         * @param {Array} wellSecId
         * @param {String} dillution
         * @returns {*|jQuery}
         */
        repeatExtractionControlWells: function (wellSecId, dillution) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/wells/repeat/XC', {'dillution': dillution});

            return this.callAction(defer, url, wellSecId);
        },

        /**
         * Confirm result for specific target on specific wells
         * @param {Array} wellSecId
         * @param {Object} assayResult
         * @returns {*|jQuery}
         */
        confirmWells: function (wellSecId, assayResult) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/wells/confirm/' + assayResult.get('id'));


            return this.callAction(defer, url, wellSecId);
        },

        /**
         * Find pcrKitLot for a pcr runs
         * @returns {*|jQuery}
         */
        getPcrKitLots: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/kitLots');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function (response) {
                    defer.resolve(response);
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        exportPlateLayout: function (params) {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/plateLayout/exportByCycler', params),
                defer = $.Deferred();
            $.ajax({
                method: 'GET',
                url: url,
                contentType: 'application/json',
                success: function (response, ignored, header) {
                    defer.resolve(header.getAllResponseHeaders().includes('filename'), header.getResponseHeader('content-disposition') ? header.getResponseHeader('content-disposition').substring(header.getResponseHeader('content-disposition').indexOf('filename=') + 9) : null, response);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },

        findAssayBySecId: function (assaySecId) {
            return this.get('assays').findWhere({secId: assaySecId});
        },
        canAssignSID: function () {
            return this.get('wells').filter(function (pcrWell) {
                return pcrWell.canChangeSmpId();
            }).length > 0;
        },
        canAssignAssay: function () {
            return this.get('wells').any(function (well) {
                return !well.get('refAssay') || !well.get('refAssay').get('secId');
            });
        },
        canClearAssay: function () {
            return this.get('wells').every(function (pcrWell) {
                return pcrWell.get('valWst') !== 5;
            }) && this.get('wells').any(function (pcrWell) {
                return pcrWell.get('refAssay') !== null;
            });
        },
        findAssayLines: function () {
            var assayLines = new Backbone.Collection();
            _.each(_.uniq(_.filter(this.get('wells').pluck('refAssay'), function (assay) {
                return assay;
            })), _.bind(function (assay) {
                var pcrW = this.get('wells').filter(function (well) {
                    return well.get('refAssay') === assay;
                });
                var assayLine = {
                    assay: {code: assay.get('code')},
                    countByType: {
                        S: 0,
                        D: 0,
                        PC: 0,
                        NC: 0,
                        RC: 0,
                        OC: 0
                    },
                    volumeBySample: assay.get('volumeBySample')
                };
                _.each(_.keys(assayLine.countByType), function (type) {
                    assayLine.countByType[type] = _.filter(pcrW, function (well) {
                        return _.isEqual(well.get('smpType'), type);
                    }).length;
                });
                assayLines.push(assayLine);
            }, this));
            return assayLines;
        },
        generateSummary: function () {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('secId') + '/report');
            window.open(url, '_blank');
        }
    });

    app.ns.PcrRun.role = RolesMixin.RUN_PCR;

    app.ns.PcrRunCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/runs/pcr/');
        },
        model: app.ns.PcrRun
    });
});

