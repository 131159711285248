define('runCreateEmptyPcrRunView',[
    'module',
    'backbone',
    'dialogFormView',
    'template!runCreateEmptyPcrRunView',
    'savingBehavior',
    'underscore',
    'jquery',
    'app',
    'bootbox',
    'settings',
    'autocompleteView',
    'moment',
    'dynamicCreateEditDate'
], function (
    module,
    Backbone,
    DialogFormView,
    Tpl,
    SavingBehavior,
    _,
    $,
    App,
    bootbox,
    Settings,
    AutocompleteView,
    moment,
    DynamicDate
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input',
            inputName: '.run-input-name',
            createRunRadio: '.create-run-radio',
            createRunFromTemplate: '.createRunFromTemplate',
            createRunFromCyclerExport: '.createRunFromCyclerExport',
            numberOfRunRegion: '.js-number-of-run-region',
            numberOfRun: '.number-of-run',
            generateStarterSheet: '.generate-starter-sheet',
            exportPlateLayout: '.export-plate-layout',
            template384: '.template384',
            selectFileFormat: '.js-select-fileFormat-region',
            blockLoading: '.empty-page-loader',
            selectedDate: '.js-selectedDate',
            forceRunName: '.js-runNameForced'
        },

        events: {
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'click .js-confirm': 'onConfirm',
            'click .js-cancel-popup': 'onCancel',
            'change @ui.createRunRadio': 'onCreateRunRadioChange',
            'change @ui.generateStarterSheet': 'onChangeGenerateStarterSheet',
            'change @ui.exportPlateLayout': 'onChangeExportPlateLayout',
            'change @ui.forceRunName': 'onChangeForceRunName'
        },

        regions: {
            selectRespUser: '.js-select-respUser-region',
            selectRunPattern: '.js-runName-pattern',
            selectRunNameDepartment: '.js-select-runName-department',
            selectRunNameGroup: '.js-select-runName-runGroup',
            selectRunNameSequenceFormat: '.js-select-runName-sequenceFormat',
            selectRunTemplate: '.js-select-run-template-region',
            selectKitProtFromCyclerExport: '.js-select-kitprot-region-from-cycler-export',
            selectKitProt: '.js-select-kitprot-region',
            selectPlateTopLeft: '#select-plate-region-top-left',
            selectPlateTopRight: '#select-plate-region-top-right',
            selectPlateBottomLeft: '#select-plate-region-bottom-left',
            selectPlateBottomRight: '#select-plate-region-bottom-right',
            selectAssay: '#select-assay-region',
            selectFileFormat: '.js-select-fileFormat-region',
            selectDate: '.js-selectDate-region'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            sync: 'onSave',
            error: 'onSaveError'
        },

        fieldsToValidate: [
        ],

        onCancel: function () {
            this.box.modal('hide');
        },

        initialize: function () {
            this.model = new Backbone.Model();
            this.model.set('runName', '');
            this.model.set('refRespUser', '');
            this.model.set('numberOfRun', 1);
            this.model.set('generateStarterSheet', false);
            this.model.set('nameForced', false);
            this.model.set('selectedDate', parseInt(moment().format('x'), 10));
            this.model.set('suffix', '');
            this.currentMode = 'fromTemplate';
            this.model.set('generateStarterSheet', false);
            this.nameForced = false;
            if (!this.model.get('pattern') || this.model.get('pattern') === '') {
                this.model.set('pattern', '');
            }
        },

        onChangeGenerateStarterSheet: function (event) {
            this.model.set('generateStarterSheet', event.target.checked);
        },

        onChangeForceRunName: function (event) {
            this.nameForced = event.target.checked;
            this.model.set('nameForced', this.nameForced);
            if (this.nameForced) {
                this.model.set('numberOfRun', 1);
                $('.js-number-of-run-region').css('display', 'none');
            } else {
                $('.js-number-of-run-region').css('display', 'block');
            }
        },

        onChangeExportPlateLayout: function (event) {
            this.exportPlateLayout = event.target.checked;
        },

        onCreateRunRadioChange: function () {
            if (this.ui.createRunRadio) {
                this.ui.createRunFromTemplate.toggleClass('hidden', true);
                this.ui.createRunFromCyclerExport.toggleClass('hidden', true);
                _.each(this.ui.createRunRadio, _.bind(function (radio) {
                    switch (radio.value) {
                        case 'fromTemplate':
                            if (radio.checked) {
                                this.ui.createRunFromTemplate.toggleClass('hidden', false);
                            }
                            break;
                        case 'fromCyclerExport':
                            if (radio.checked) {
                                this.ui.createRunFromCyclerExport.toggleClass('hidden', false);
                            }
                            break;
                    }
                    if (radio.checked) {
                        this.currentMode = radio.value;
                    }
                }, this));
            }
        },

        onDepartmentChange: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
                this.model.set(fieldName, model.get('code'));
            } else {
                this.model.set(fieldName, null);
            }
        },

        onRunGroupChange: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
                this.model.set(fieldName, model.get('code'));
            } else {
                this.model.set(fieldName, null);
            }
        },

        onRunSequenceFormatChange: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
                this.model.set(fieldName, model.get('code'));
            } else {
                this.model.set(fieldName, null);
            }
        },

        onPatternChange: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
                this.model.set(fieldName, model.get('code'));
            } else {
                this.model.set(fieldName, null);
            }
        },

        showSelectRunTemplateView: function () {
            if (this.selectRunTemplateView) {
                this.selectRunTemplateView.destroy();
            }
            var PcrRunTemplateService = require('services/caccounts/pcrruntemplates');
            this.selectRunTemplateView = new AutocompleteView(
                this._getAutocompleteOptionObject(PcrRunTemplateService.getAutocompleteParam({
                    modelProperty: 'refRunTemplate',
                    callBackOnChange: this.onTemplateChange
                }))
            );
            this.getRegion('selectRunTemplate').show(this.selectRunTemplateView);
            this.triggerMethod('enable:cancel:confirm');
        },

        showPatternDropDown: function () {
            if (this.selectPatternView) {
                this.selectPatternView.destroy();
            }
            var UserCodeListService = require('services/caccounts/userCodelist');
            this.selectPatternView = new AutocompleteView(
                this._getAutocompleteOptionObject(
                    UserCodeListService.getAutocompleteParam({
                        modelProperty: 'pattern',
                        codeListCode: 'PATTERN_PCR',
                        callBackOnChange: this.onPatternChange
                    })));
            this.getRegion('selectRunPattern').show(this.selectPatternView);
        },

        showDepartmentDropDown: function () {
            if (this.selectDepartmentView) {
                this.selectDepartmentView.destroy();
            }
            var UserCodeListService = require('services/caccounts/userCodelist');
            this.selectDepartmentView = new AutocompleteView(
                this._getAutocompleteOptionObject(
                    UserCodeListService.getAutocompleteParam({
                        modelProperty: 'department',
                        codeListCode: 'DEPT',
                        callBackOnChange: this.onDepartmentChange
                    })));
            this.getRegion('selectRunNameDepartment').show(this.selectDepartmentView);
        },

        showRunGroupDropDown: function () {
            if (this.selectRunGroupView) {
                this.selectRunGroupView.destroy();
            }
            var UserCodeListService = require('services/caccounts/userCodelist');
            this.selectRunGroupView = new AutocompleteView(
                this._getAutocompleteOptionObject(
                    UserCodeListService.getAutocompleteParam({
                        modelProperty: 'group',
                        codeListCode: 'RUNGROUP_PCR',
                        callBackOnChange: this.onRunGroupChange
                    })));
            this.getRegion('selectRunNameGroup').show(this.selectRunGroupView);
        },

        showSequenceFormatDropDown: function () {
            if (this.selectRunSequenceFormatView) {
                this.selectRunSequenceFormatView.destroy();
            }
            var UserCodeListService = require('services/caccounts/userCodelist');
            this.selectRunSequenceFormatView = new AutocompleteView(
                this._getAutocompleteOptionObject(
                    UserCodeListService.getAutocompleteParam({
                        modelProperty: 'sequenceFormat',
                        codeListCode: 'SEQUENCE',
                        callBackOnChange: this.onRunSequenceFormatChange
                    })));
            this.getRegion('selectRunNameSequenceFormat').show(this.selectRunSequenceFormatView);
        },

        onChangeAssay: function (fieldName, model) {
            this.currentAssay = model;
            this.showEPlate();
        },
        onChangePlate: function (fieldName, model) {
            this.onChange(fieldName, model);
            this.showEPlate();
        },

        generateIgnoredName: function (excepted) {
            var ignoredNames = [];
            if (excepted !== 'refPlateTopLeft' && this.model.get('refPlateTopLeft')) {
                ignoredNames.push(this.model.get('refPlateTopLeft').get('name'));
            }
            if (excepted !== 'refPlateTopRight' && this.model.get('refPlateTopRight')) {
                ignoredNames.push(this.model.get('refPlateTopRight').get('name'));
            }
            if (excepted !== 'refPlateBottomLeft' && this.model.get('refPlateBottomLeft')) {
                ignoredNames.push(this.model.get('refPlateBottomLeft').get('name'));
            }
            if (excepted !== 'refPlateBottomRight' && this.model.get('refPlateBottomRight')) {
                ignoredNames.push(this.model.get('refPlateBottomRight').get('name'));
            }
            return ignoredNames;
        },

        showEPlate: function () {
            var params = Settings.generateParamUrl({
                assaySecId: this.currentAssay ? this.currentAssay.get('secId') : null,
                ignoredNames: this.generateIgnoredName('refPlateTopLeft')
            });
            this.getRegion('selectPlateTopLeft').show(new AutocompleteView(
                this._getAutocompleteOptions('refPlateTopLeft', 'name', 'name', 'rest/v2/runs/e-plate' + (params ? '?' + params : ''),
                    'selectplate.placeholder', 'name', this.onChangePlate)
            ));
            params = Settings.generateParamUrl({
                assaySecId: this.currentAssay ? this.currentAssay.get('secId') : null,
                ignoredNames: this.generateIgnoredName('refPlateTopRight')
            });
            this.getRegion('selectPlateTopRight').show(new AutocompleteView(
                this._getAutocompleteOptions('refPlateTopRight', 'name', 'name', 'rest/v2/runs/e-plate' + (params ? '?' + params : ''),
                    'selectplate.placeholder', 'name', this.onChangePlate)
            ));
            params = Settings.generateParamUrl({
                assaySecId: this.currentAssay ? this.currentAssay.get('secId') : null,
                ignoredNames: this.generateIgnoredName('refPlateBottomLeft')
            });
            this.getRegion('selectPlateBottomLeft').show(new AutocompleteView(
                this._getAutocompleteOptions('refPlateBottomLeft', 'name', 'name', 'rest/v2/runs/e-plate' + (params ? '?' + params : ''),
                    'selectplate.placeholder', 'name', this.onChangePlate)
            ));
            params = Settings.generateParamUrl({
                assaySecId: this.currentAssay ? this.currentAssay.get('secId') : null,
                ignoredNames: this.generateIgnoredName('refPlateBottomRight')
            });
            this.getRegion('selectPlateBottomRight').show(new AutocompleteView(
                this._getAutocompleteOptions('refPlateBottomRight', 'name', 'name', 'rest/v2/runs/e-plate' + (params ? '?' + params : ''),
                    'selectplate.placeholder', 'name', this.onChangePlate)
            ));
        },

        onChangeFileFormat: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            this.fileFormatSecId = model.get('secId');
        },

        onRender: function () {
            this.showPatternDropDown();
            this.showDepartmentDropDown();
            this.showRunGroupDropDown();
            this.showSequenceFormatDropDown();

            this.getRegion('selectRunTemplate').options.allowMissingEl = true;
            this.showSelectRunTemplateView();

            this.getRegion('selectAssay').show(new AutocompleteView(
                this._getAutocompleteOptions('refAssay', 'code', 'code', 'rest/v2/assays/',
                    'selectassay.placeholder', 'code', this.onChangeAssay)
            ));
            var FileFormatPlateLayoutService = require('services/caccounts/fileFormatPlateLayouts');
            this.getRegion('selectFileFormat').show(new AutocompleteView(
                this._getAutocompleteOptionObject(FileFormatPlateLayoutService.getAutocompleteParamForImport({
                    modelProperty: 'refFileFormat',
                    callBackOnChange: this.onChangeFileFormat
                }))
            ));
            this.showEPlate();
            this.triggerMethod('enable:cancel:confirm');
            this.showRefRespUserDropDown();
            this.showChildView('selectDate', new DynamicDate({
                model: this.model,
                field: 'selectedDate',
                value: this.model.get('selectedDate')
            }));
        },

        showRefRespUserDropDown: function () {
            if (this.respUserView) {
                this.respUserView.destroy();
            }
            var UserpidService = require('services/caccounts/userpids');
            this.respUserView = new AutocompleteView(
                this._getAutocompleteOptionObject(UserpidService.getAutocompleteParam({
                    modelProperty: 'refRespUser',
                    valueProperty: 'nickName',
                    queryProperty: 'nickName',
                    valueKey: 'nickName',
                    callBackOnChange: this.onRespUserChange
                }))
            );
            this.getRegion('selectRespUser').show(this.respUserView);
        },

        onRespUserChange: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            this.onChange(fieldName, model ? model : null);
        },

        onTemplateChange: function (fieldName, model) {
            this.model.set('refRunTemplate', model);
            // var displayTemplateDate = false;
            // var displayTemplateNumberOfRuns = false;
            var displayTemplate384 = false;

            this.model.set('generateStarterSheet', false);
            if (model) {
                if (model.get('pattern') && model.get('pattern') !== '') {
                    this.model.set('pattern', model.get('pattern'));
                    this.showPatternDropDown();
                }
                if (model.get('department') && model.get('department') !== '') {
                    this.model.set('department', model.get('department'));
                    this.showDepartmentDropDown();
                }
                if (model.get('group') && model.get('group') !== '') {
                    this.model.set('group', model.get('group'));
                    this.showRunGroupDropDown();
                }
                if (model.get('sequenceFormat') && model.get('sequenceFormat') !== '') {
                    this.model.set('sequenceFormat', model.get('sequenceFormat'));
                    this.showSequenceFormatDropDown();
                }
                if (model.get('suffix') && model.get('suffix') !== '') {
                    this.model.set('suffix', model.get('suffix'));
                    $('.js-runName-suffix').find('input').val(model.get('suffix'));
                }
                if (model.get('pattern') && model.get('pattern') !== '') {
                    this.model.set('pattern', model.get('pattern'));
                    $('.js-runName-pattern').find('input').val(model.get('pattern'));
                }
                this.model.set('generateStarterSheet', model.get('printRunSheet'));
            }
            this.ui.generateStarterSheet.attr('checked', this.model.get('generateStarterSheet'));

            this.ui.selectedDate.removeClass('invalid');
            this.ui.numberOfRun.removeClass('invalid');
            this.ui.numberOfRunRegion.closest('div[data-field-name=numberOfRun]').removeClass('has-error');

            this.model.set('numberOfRun', 1);

            if (model !== null) {
                displayTemplate384 = model.get('wellCount') > 96;
            }

            this.ui.template384.toggleClass('hidden', !displayTemplate384);
            this.exportPlateLayout = false;
            this.ui.exportPlateLayout[0].checked = false;
            this.fileFormatSecId = null;
        },

        onConfirm: function () {
            var CreateRunFromTemplate = Backbone.Model.extend({
                defaults: {
                    'refRunTemplate': '',
                    'selectedDate': '',
                    'numberOfRun': '',
                    'refPlateTopLeft': '',
                    'refPlateTopRight': '',
                    'refPlateBottomLeft': '',
                    'refPlateBottomRight': '',
                    'refAssay': ''
                },
                idAttribute: 'secId',

                postUrl: function () {
                    return Settings.url('rest/v2/runs/pcr/fromTemplate');
                },

                save: function () {
                    this.url = this.postUrl();
                    return Backbone.Model.prototype.save.call(this);
                }
            });
            var createRunFromTemplate = new CreateRunFromTemplate();
            createRunFromTemplate.set('refRunTemplate', (this.model.get('refRunTemplate') && this.model.get('refRunTemplate').get('secId') ? this.model.get('refRunTemplate').get('secId') : ''));
            createRunFromTemplate.set('suffix', (this.model.get('suffix') ? this.model.get('suffix') : ''));
            createRunFromTemplate.set('nameForced', (this.model.get('nameForced') ? this.model.get('nameForced') : false));
            createRunFromTemplate.set('name', (this.model.get('name') ? this.model.get('name') : ''));
            createRunFromTemplate.set('pattern', (this.model.get('pattern') ? this.model.get('pattern') : ''));
            createRunFromTemplate.set('department', (this.model.get('department') ? this.model.get('department') : ''));
            createRunFromTemplate.set('group', (this.model.get('group') ? this.model.get('group') : ''));
            createRunFromTemplate.set('sequenceFormat', (this.model.get('sequenceFormat') ? this.model.get('sequenceFormat') : ''));
            createRunFromTemplate.set('respUserSecId', (this.model.get('refRespUser') && this.model.get('refRespUser').get('secId') ?
                this.model.get('refRespUser').get('secId') : ''));

            var needToCheckNumberOfRuns = true;
            if (this.model.get('nameForced')) {
                needToCheckNumberOfRuns = false;
                createRunFromTemplate.set('numberOfRun', this.model.get('numberOfRun'));
            }

            var template384 = this.model.get('refRunTemplate').get('wellCount') > 96;
            if (!this.validate([{name: 'refRunTemplate', type: 'required'}])) {
                bootbox.alert(_.i18n('run.pcr.template.required'));
                return;
            }

            if (!this.validate([{name: 'selectedDate', type: 'required'}]) ||
                !this.validate([{name: 'selectedDate', type: 'datePicker'}])) {
                bootbox.alert(_.i18n('run.pcr.date.required'));
                return;
            }
            createRunFromTemplate.set('selectedDate', moment(this.model.get('selectedDate')).format());

            if (needToCheckNumberOfRuns) {
                if (!this.validate([{name: 'numberOfRun', type: 'required'}])) {
                    bootbox.alert(_.i18n('run.pcr.numberofruns.required'));
                    return;
                }
                var numberOfRun = parseInt(this.model.get('numberOfRun'), 10);
                var maxNumberOfRun = parseInt(this.$el.find(this.ui.numberOfRun).attr('max'), 10);
                if (numberOfRun > maxNumberOfRun) {
                    bootbox.alert(_.i18n('run.pcr.numberofruns.max', maxNumberOfRun));
                    return;
                }
                createRunFromTemplate.set('numberOfRun', numberOfRun);
            } else {
                createRunFromTemplate.set('numberOfRun', 1);
            }
            if (template384) {
                var check = !this.validate([{name: 'refPlateTopLeft', type: 'required'}]);
                check = check && !this.validate([{name: 'refPlateTopRight', type: 'required'}]);
                check = check && !this.validate([{name: 'refPlateBottomLeft', type: 'required'}]);
                check = check && !this.validate([{name: 'refPlateBottomRight', type: 'required'}]);

                if (check) {
                    return;
                }
                createRunFromTemplate.set('refPlateTopLeft', this.model.get('refPlateTopLeft').get('secId'));
                createRunFromTemplate.set('refPlateTopRight', this.model.get('refPlateTopRight').get('secId'));
                createRunFromTemplate.set('refPlateBottomLeft', this.model.get('refPlateBottomLeft').get('secId'));
                createRunFromTemplate.set('refPlateBottomRight', this.model.get('refPlateBottomRight').get('secId'));
                createRunFromTemplate.set('refAssay', this.currentAssay ? this.currentAssay.get('secId') : '');
            }
            var loader = $('.js-global-loader');
            loader.show();

            createRunFromTemplate.save().done(
                _.bind(function (result) {
                    this.options.callBackOnClose(result);
                    var url;
                    if (this.model.get('generateStarterSheet')) {
                        var runSecIds = result.map(function (run) {
                            return run.secId;
                        });
                        this.options.service.generateRunSheet(runSecIds);
                    }
                    if (this.exportPlateLayout && this.fileFormatSecId && this.model.get('refPlateTopLeft').get('secId') && this.model.get('refPlateTopRight').get('secId') && this.model.get('refPlateBottomLeft').get('secId') && this.model.get('refPlateBottomRight').get('secId')) {
                        url = Settings.url('rest/v2/runs/pcr/' + this.model.get('refPlateTopLeft').get('secId') + '/plateLayout/export', {
                            'fileFormatSecId': this.fileFormatSecId,
                            'plateLinkedType': 'TOP_LEFT'
                        });
                        window.open(url, '_blank');
                        url = Settings.url('rest/v2/runs/pcr/' + this.model.get('refPlateTopRight').get('secId') + '/plateLayout/export', {
                            'fileFormatSecId': this.fileFormatSecId,
                            'plateLinkedType': 'TOP_RIGHT'
                        });
                        window.open(url, '_blank');
                        url = Settings.url('rest/v2/runs/pcr/' + this.model.get('refPlateBottomLeft').get('secId') + '/plateLayout/export', {
                            'fileFormatSecId': this.fileFormatSecId,
                            'plateLinkedType': 'BOTTOM_LEFT'
                        });
                        window.open(url, '_blank');
                        url = Settings.url('rest/v2/runs/pcr/' + this.model.get('refPlateBottomRight').get('secId') + '/plateLayout/export', {
                            'fileFormatSecId': this.fileFormatSecId,
                            'plateLinkedType': 'BOTTOM_RIGHT'
                        });
                        window.open(url, '_blank');
                    }
                    this.hide();
                }, this)
            ).fail(
                _.bind(function (response) {
                    if (response.status === 409) {
                        this.ui.selectedDate.addClass('invalid');
                    }
                }, this)
            ).always(
                _.bind(function () {
                    loader.hide();
                }, this)
            );
        },

        onSave: function () {
            this.hide();
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.onDuplicateCode();
                this.triggerMethod('hide:label');
            }
        },

        onDuplicateCode: function () {
            this.ui.endUserIdInput.popover('show');
            this.ui.endUserIdInput.addClass('invalid');
            this.ui.endUserIdInput.closest('div[data-field-name=code]').addClass('has-error');
        },

        onFocusOut: function () {
            var $target,
                datepicker;

            // close popover when click outside
            setTimeout(_.bind(function () {
                $target = $(document.activeElement);
                datepicker = $target.parents('.datepicker-element').length > 0 ||
                    $target.parents('.ui-datepicker').length > 0 ||
                    $target.hasClass('ui-datepicker') ||
                    $target.get(0) === this.$el.get(0) ||
                    $target.hasClass('popover-shown');

                if (!datepicker) this.trigger('close:popover');
            }, this), 0);
        }
    });
});

